import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const EmploymentApplication = () => {
  return (
    <>
      <Layout>
        <SEO title="Employment Application" />
        <div className="employment-app-main">
          <div className="container">
            <h1 className="text-center mb-5">Employment Application</h1>
            <iframe
              className="pacific-coast-application"
              src="https://forms.zohopublic.com/virtualoffice9340/form/PacificCoastApplicationForm/formperma/xwfXoIs6B-_y2ijtIH0WIEPK-Q71E6UKDhc1P8oAh6Y"
              frameborder="0"
              title="Pacific Coast Application Form"
              width="100%"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default EmploymentApplication
